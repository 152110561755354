
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import resize from 'vue-resize-directive';

@Component({
	directives: {
		resize,
	},
	components: {},
})
export default class AutoResizeSticky extends Vue {
	@Prop({ default: 0 }) public offsetTop;
	@Prop() public offsetBottom;
	@Prop({ default: '' }) public stickyClass;

	public mounted() {
		this.$root.$on('container-resize', this.onResize);
	}

	public onChange(value: boolean) {
		this.$emit('change', value);
	}

	public onResize() {
		if (this.$refs.mySticky as any) {
			(this.$refs.mySticky as any).handleResize();
		}
	}
	public handleScroll() {
		(this.$refs.mySticky as any).handleScroll();
	}
	public handleResize() {
		(this.$refs.mySticky as any).handleResize();
	}
}
