
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import accountx from '@/store/modules/accountx';
import VueRouter, { Route, RawLocation } from 'vue-router';
import userx from '@/store/modules/userx';
import { AccountData } from '@/store/models.def';
import Daily from '@/components/reports/components/filterables/daily/Daily.vue';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import PageError from '@/components/containers/PageError.vue';
import AccountSyncBar from './AccountSyncBar.vue';
import resize from 'vue-resize-directive';
import BaseReport from '../reports-v2/BaseReport.vue';
import AutoResizeSticky from '@/components/AutoResizeSticky.vue';
import ScrollTop from 'primevue/scrolltop';
import Message from 'primevue/message';

@Component({
	directives: {
		resize,
	},
	components: {
		AccountSyncBar,
		PageError,
		AutoResizeSticky,
		ScrollTop,
		Message,
	},
})
export default class AccountContainer extends Vue {
	public myUnexistent: boolean = false;
	public myUnauthorized: boolean = false;
	public isReport: boolean = false;

	public get fullAccountData() {
		return accountx.fullAccountData;
	}
	public get currentAccountId() {
		return accountx.currentAccountId;
	}
	public get unexistent() {
		return this.myUnexistent || accountx.unexistent;
	}
	public get unauthorized() {
		return this.myUnauthorized || accountx.unauthorized;
	}

	public get accountReady() {
		return !this.unauthorized && !this.unexistent && this.fullAccountData;
	}

	public onIsReport(value: boolean) {
		this.isReport = value;
	}

	public get loadingText() {
		if (gdbx.loadProgress === 'Loading') {
			return 'Loading data';
		}
		if (gdbx.loadProgress === 'Processing') {
			return 'Processing data';
		}
		const progress = gdbx.loadProgress as number;
		return `Loaded...${(progress / 1000).toFixed(0)}K`;
	}
	public get loadingVariant() {
		if (gdbx.loadProgress === 'Loading') {
			return 'teal';
		}
		return 'orange';
	}

	public get showLoading() {
		return (
			gdbx.loadProgress !== 'Idle' &&
			gdbx.loadProgress !== 'Completed' &&
			this.isReport
		);
	}

	public get expired() {
		if (!this.fullAccountData || !this.fullAccountData.token) {
			return false;
		}
		return this.fullAccountData.token.forbidden;
	}

	public get isDemoUser() {
		if (userx.user && userx.user.email === 'demo@gobi.my') {
			return true;
		}
		return false;
	}
	public get versionIncompatible() {
		return gdbx.versionIncompatible;
	}

	public get isFreeVersion() {
		const key = 'programId';

		if (this.fullAccountData && this.fullAccountData[key] === 'sql-dashboard') {
			return true;
		}
		return false;
	}

	public get isCoreVersion() {
		const key = 'programId';

		if (this.fullAccountData && this.fullAccountData[key] === 'sql-core') {
			return true;
		}
		return false;
	}

	public get currentPath() {
		const path = this.$route.path.split('/');
		return path[3];
	}

	public onResize() {
		this.$root.$emit('container-resize');
	}
}
