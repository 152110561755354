
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
	components: {},
})
export default class PageError extends Vue {
	@Prop({ default: 'notFound' }) public readonly type!: string;
	@Prop({ default: '' }) public readonly caption!: string;

	public get text() {
		if (this.caption) {
			return this.caption;
		}
		if (this.type === 'notFound') {
			return 'Page Not Found.';
		}
		if (this.type === 'forbidden') {
			return 'Forbidden.';
		}
		return 'Error occured.';
	}

	public get svgUrl() {
		let name = 'gobi-desert-not-found';
		if (this.type === 'forbidden') {
			name = 'gobi-desert-keepout';
		}
		return `img/errors/${name}.svg`;
	}
}
