
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { EditState, CommandData } from '@/store/models.def';
import accountx from '@/store/modules/accountx';
import fb from '@/store/gobi-firestore';
import mediumwarex from '@/store/modules/mediumwarex';

const db = fb.db;

@Component({
	components: {},
})
export default class SyncNowButton extends Vue {

	public get accountId() {
		return accountx.currentAccountId;
	}
	public state: EditState = 'active';

	public async onClick() {
		this.state = 'sending';
		await Promise.all([this.syncNow(), this._wait()]);
		this.state = 'active';
	}

	public async syncNow() {
		if (!this.accountId) {
			return;
		}
		const response = await mediumwarex.syncNow(this.accountId);
		if (!response.success || !response.command) {
			return;
		}
		await this.waitSyncNowComplete(response.command!.commandId);
		await this._wait(500);
		// TODO clear history and refresh report
		return;
	}

	public waitSyncNowComplete(commandId: string) {
		return new Promise((resolve, reject) => {
			const unsubscribe = db.doc(`commands/${commandId}`).onSnapshot(
				(doc) => {
					if (!doc.exists) {
						unsubscribe();
						reject(new Error('not-exists'));
					}
					const data = doc.data() as CommandData;
					if (data.inactive) {
						unsubscribe();
						resolve();
					}
				},
				(error) => {
					unsubscribe();
					reject(error);
				},
			);
		});
	}

	protected _wait(duration: number = 1000) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, duration);
		});
	}
}
