import {
	CommandData, CommandReponse,
} from '@/store/models.def';
import fb from '@/store/gobi-firestore';
import firebase from 'firebase/app';

const db: firebase.firestore.Firestore = fb.db;
const functions: firebase.functions.Functions = fb.functions;

export class Mediumwares {

	public db: firebase.firestore.Firestore | null = null;
	public functions: firebase.functions.Functions | null = null;

	public async syncNow(accountId: string): Promise<CommandReponse> {
		try {
			const result = await functions
				.httpsCallable('postCommand')({
					accountId,
					type: 'syncNow',
				});

			const command = result.data as CommandData;
			return { success: true, command };
		} catch (error) {
			console.error(error);
			return { success: false, error, errorCode: error.code, errorMessage: error.message };
		}
	}
}
const mwz = new Mediumwares();
export default mwz;
