import {
	getModule,
	Module,
	VuexModule,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import store from '@/store';
import mwz from '../mediumwarez';
import {
	AccountData, CreateAccountSubmission, ValueText, UpdateAccountSubmission, ChangeSchemaSubmission,
	ExpireCurrentTokenSubmission,
} from '@/store/models.def';
@Module({
	namespaced: true,
	name: 'mwx',
	store,
	dynamic: true,
})
class MediumwaresModule extends VuexModule {


	public get softwareTypesVt(): ValueText[] {
		return [
			{ value: 'sql', text: 'SQL' },
			{ value: 'autocount', text: 'AutoCount' },
		];
	}

	@Action
	public async syncNow(accountId: string) {
		const result = await mwz.syncNow(accountId);
		return result;
	}

}


export default getModule(MediumwaresModule);
