
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { EditState } from '@/store/models.def';

@Component({
	components: {},
})
export default class RefreshButton extends Vue {
	public state: EditState = 'active';

	public async onClick() {
		this.state = 'sending';
		// TODO clear history and refresh report
		this.state = 'active';
	}

	protected _minimumTime() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, 1000);
		});
	}
}
