
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import accountx from '@/store/modules/accountx';
import moment from 'moment';
import { EditState } from '@/store/models.def';
import SyncNowButton from './SyncNowButton.vue';
import RefreshButton from './RefreshButton.vue';

@Component({
	components: {
		RefreshButton,
		SyncNowButton,
	},
})
export default class AccountSyncBar extends Vue {
	public refreshState: EditState = 'active';

	public get lastSync() {
		return (
			accountx.fullAccountData &&
			moment(accountx.fullAccountData.lastSync).format('DD MMM YYYY HH:mm:ss')
		);
	}
	public get lastUpdate() {
		return (
			accountx.fullAccountData &&
			moment(accountx.fullAccountData.lastUpdate).format('DD MMM YYYY HH:mm:ss')
		);
	}
}
